import React from 'react';
import styled from 'styled-components';

import HeaderText from '../Text/HeaderText/HeaderText';
import CloseIcon from '../CloseIcon/CloseIcon';
import {ReactComponent as InfoIcon} from 'icons/info-alt.svg';

const Wrapper = styled.div`
  background: ${(props) =>
    props.theme.components.detailsNavigation?.bgColor ||
    props.theme.colors.body ||
    props.theme.colors.primary};
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 8px 10px;
  min-height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavIcon = styled.div<{position?: string}>`
  position: absolute;
  ${(props) => props.position || 'left'}: 14px;
  fill: ${(props) =>
    props.theme.components.detailsNavigation?.iconColor ||
    props.theme.components.detailsNavigation?.color ||
    props.theme.colors.white};
  cursor: pointer;
  width: 25px;
`;

const Text = styled(HeaderText)`
  display: block;
  text-align: center;
  color: ${(props) =>
    props.theme.components.detailsNavigation?.color ||
    props.theme.colors.white};
  font-family: ${(props) => props.theme.font.secondary.name};
  font-weight: ${(props) =>
    props.theme.components.detailsNavigation?.fontWeight ||
    props.theme.font.styles.detailsHeader.fontWeight};
  padding: 2px 25px;
`;

export type ActionButton = {
  Icon: React.ComponentType<any>;
  onClick: (...params: any) => any;
}

interface DetailsNavigationProps {
  text: any;
  onClose: (...params: any) => any;
  onInfo?: (...params: any) => any;
  actionButton?: ActionButton;
}

const DetailsNavigation = (props: DetailsNavigationProps) => {
  const {text, onClose, onInfo, actionButton} = props;

  return (
    <Wrapper>
      <NavIcon onClick={onClose}>
        <CloseIcon iconType={'times'} />
      </NavIcon>
      <Text>{text}</Text>
      {onInfo && (
        <NavIcon position="right" onClick={onInfo}>
          <InfoIcon />
        </NavIcon>
      )}
      {actionButton && (
        <NavIcon position="right" onClick={actionButton.onClick}>
          <actionButton.Icon />
        </NavIcon>
      )}
    </Wrapper>
  );
};

export default DetailsNavigation;
