import {fetchAPiClient} from './api';
import {Voucher} from 'types/Offers';
import {Location} from 'types/Location';
import {QRCodeDetails} from 'constants/qr-codes';

const defaultApiClient = fetchAPiClient({
  subdomain: 'api',
  context: 'coins',
});

interface GetVouchers {
  location?: Location;
  types?: string;
  business_types?: string;
  offer_type_id?: number;
  climate_partner_id?: number;
  wishlisted?: boolean;
  limit?: number;
  page?: number;
}

interface GetPurchasedVouchers {
  types?: string;
  redeemed?: number;
}

export const getPurchasedVouchers = async (params: GetPurchasedVouchers) => {
  const client = defaultApiClient;

  const url = '/v1/offers/codes/purchased';
  const purchasedVouchersResponse = await client.get<any>(url, {
    params: {limit: 100, ...params},
  });
  return purchasedVouchersResponse.data;
};

type VouchersResponse = Voucher[];

export const getVouchers = async ({
  location,
  types,
  climate_partner_id,
  wishlisted,
  limit,
  page,
  business_types,
  offer_type_id,
}: GetVouchers): Promise<VouchersResponse> => {
  const client = defaultApiClient;
  const url = '/v1/offers';
  const {data} = await client.get<any>(url, {
    params: {
      limit: limit || 1000,
      page,
      types,
      climate_partner_id,
      wishlisted,
      business_types,
      offer_type_id,
      ...(location || {}),
    },
  });

  return (data || []).map(parseVoucher);
};

export const getAllVouchers = async (params: GetVouchers) => {
  return await getVouchers(params);
};

interface GetOfflineVouchers {
  location?: Location;
}

export const getOfflineVouchers = async ({location}: GetOfflineVouchers) => {
  return await getVouchers({location, types: '2'});
};

export const getWishlistedVouchers = async ({location}: GetVouchers) => {
  const vouchers = await getVouchers({
    location,
    wishlisted: true,
    types: '1,2',
  });

  return vouchers.map((offer: Voucher) => ({
    offer,
    id: offer.id,
  }));
};

export const getClimatePartnerVouchers = async ({
  id,
}: {
  id: number;
}): Promise<VouchersResponse> => {
  return await getVouchers({types: '2', climate_partner_id: id});
};

export const getActiveVouchers = async (params: GetVouchers) => {
  const allVouchers = await getAllVouchers(params);
  return allVouchers
    .filter((voucher: any) => {
      return voucher.enabled || voucher.voucher_codes_available;
    })
    .sort((a: any, b: any) => a.order - b.order);
};

export const purchaseVoucher = async ({data}: {data: any}) => {
  const client = defaultApiClient;
  const url = `/v1/offers/${data.voucher_id}/purchase`;
  return client.post<any>(url, data);
};

export const markVoucherAsUsed = async ({id}: {id: number}) => {
  const client = defaultApiClient;
  const url = `/v1/offers/codes/${id}/redeemed`;
  await client.post<any>(url, {});
};

export const getVoucher = async ({id}: {id: number}) => {
  const client = defaultApiClient;
  const url = `/v1/offers/${id}`;
  const voucher = await client.get<never, Voucher>(url);
  return parseVoucher(voucher);
};

export const getVoucherTags = () => {
  const client = defaultApiClient;
  const url = '/v1/tag/voucher';
  return client.get<any>(url, {params: {per_page: 500}});
};

export const addVoucherToWishlist = ({id}: {id: number}) => {
  const client = defaultApiClient;
  const url = `/v1/offers/${id}/wishlist`;
  return client.post<any>(url);
};

export const removeVoucherFromWishlist = ({id}: {id: number}) => {
  const client = defaultApiClient;
  const url = `/v1/offers/${id}/wishlist`;
  return client.delete(url);
};

export const getQRCodeDetails = ({
  url,
}: {
  url: string;
}): Promise<QRCodeDetails> => {
  return defaultApiClient.post<Record<string, string>, QRCodeDetails>(
    `/v1/qrcodes/scan`,
    {
      payload: url,
    },
  );
};

export const scanQRCodeURL = async ({
  url,
}: {
  url: string;
}): Promise<QRCodeDetails> => {
  return defaultApiClient.get<any, QRCodeDetails>(url, {
    params: {
      scan: true,
    },
  });
};

type GetClimatePartnersParams = {
  location?: Location;
};
export const getClimatePartners = async ({
  location,
}: GetClimatePartnersParams): Promise<VouchersResponse> => {
  const client = defaultApiClient;
  const url = '/v1/climate-partners';
  const {data} = await client.get<any>(url, {
    params: {
      limit: 1000000,
      ...(location || null),
    },
  });

  return data;
};

export const getBusinessTypes = async () => {
  const client = defaultApiClient;
  return client.get<any>('/v1/offers/business-types');
};

export const searchMap = async (query: string): Promise<any> => {
  const client = defaultApiClient;

  return await client.get('/v1/map/search', {
    params: {
      query: query,
    },
  });
};

export const getFeatures = async (): Promise<any> => {
  const client = defaultApiClient;

  return await client.get('/v1/map/features');
};

export const getMapFilters = async (): Promise<any> => {
  const client = defaultApiClient;

  return await client.get('/v1/map/filters');
};

const parseVoucher = (voucher: Voucher) => ({
  ...voucher,
  climate_partner: voucher.climate_partners?.sort(
    (a, b) => (a.distance || 0) - (b.distance || 0),
  )[0],
});
