/* eslint-disable */
import React, {createContext, useState} from 'react';
import {createComponent} from '@lit-labs/react';
import {getUserAuth} from 'sdkwidgets';
import {Env} from 'sdkwidgets/lib/types';
import {fetchAPiClient} from '../services/api';

type OpenWidgetContextObject = {
  widgetRegistry: any[];
  logs: any[];
};

export const OpenWidgetContext = createContext<OpenWidgetContextObject>({
  widgetRegistry: [],
  logs: [],
});

type appEnvMapType = {
  [key: string]: string;
};

/* APP_ENV contains staging while app needs stage */
const appEnvMap: appEnvMapType = {
  local: 'local',
  development: 'development',
  staging: 'stage',
  production: 'production',
};

const OpenWidgetProvider = (props: any) => {
  const [widgetsLoading, setWidgetsLoading] = useState<any[]>([]);
  const [widgetRegistry, setWidgetRegistry] = useState<any[]>([]);
  const [logs, setLogs] = useState<any[]>([]);


  // @ts-ignore
  window.initDemo = async function (reset) {
    let env: Env = (process.env.REACT_APP_ENV || 'production') as Env;
    if (appEnvMap[env as Env]) env = appEnvMap[env] as Env;

    console.log("Initializing demo", env);

    const apiClient = fetchAPiClient({subdomain: 'sst'});

    let app:string|null = localStorage.getItem("app");
    let token:string|null = localStorage.getItem("token");

    console.log("Initializing demo", env, app, token);

    // Disable saving tokens in localstorage
    app = null;
    token = null;

    if (!app || !token) {
      let url = '/demo/initialize';

      if (reset)
        url = '/demo/initialize?new=true';
      
      let data = await apiClient.post(url, {}, {
        headers: {
          'x-agent': 'ChangersAuto/7.4.3 (changers.blacksquared.com; build:7.4.3.12; iOS 13.5.1) Alamofire/5.1.0 SDK',
          'x-app': 'klimataler'
        }
      });

      console.log("Init api response", data);

      app = (data as any).app;
      token = (data as any).token;

      localStorage.setItem("app", app || '');
      localStorage.setItem("token", token || '');

      console.log("Saved user", env, app, token);
    }

    window.dispatchEvent(
      new CustomEvent('Native_App_Response', {
        detail: {
          permission: [
            {
              motion: true,
              gps: true,
              notification: true,
            },
          ],
          type: 'initialize',
          env: env,
          app_name: app,
          token: token,
          data: {
            lastCo2Avoided: 0,
          },
          headers: {
            'x-app': app,
            'x-agent':
              '(ChangersAuto/1.0.0) (com.blacksquared.changers-sdk) SDK',
          },
          motiontag: {
            last_transmitted: '08.09.20, 11:05',
            last_event: '08.09.20, 11:05',
          },
          pp_accepted: true,
          app_version: '1.0.0',
          features: ['climate-partners', 'home-qr-code-scanner', 'user-account', 'share-badge'], 
        },
      }),
    );
    
    window.dispatchEvent(
      new CustomEvent('Native_App_Response', {
        detail: {
          type: 'source_enabled'
        },
      }),
    );

    window.dispatchEvent(
      new CustomEvent('Native_App_Response', {
        detail: {
          type: 'permissions_status',
          permission: [
            {
              motion: true,
              gps: true,
              notification: true,
            },
          ],
        },
      }),
    );

  };

  // @ts-ignore
  window.initWidgets = async function (
    app: string,
    clientId: number,
    clientSecret: string,
    uuid?: string,
  ) {
    let env: Env = (process.env.REACT_APP_ENV || 'production') as Env;
    if (appEnvMap[env as Env]) env = appEnvMap[env] as Env;

    getUserAuth({app, clientId, clientSecret, uuid, env}).then(function (data) {
      console.info(`Initializing app for user ${data?.uuid}`);

      window.dispatchEvent(
        new CustomEvent('Native_App_Response', {
          detail: {
            permission: [
              {
                motion: true,
                gps: true,
                notification: true,
              },
            ],
            type: 'initialize',
            env: env,
            app_name: data?.app,
            token: data?.userToken,
            data: {
              lastCo2Avoided: 0,
            },
            headers: {
              'x-app': data?.app,
              'x-agent':
                '(ChangersAuto/1.0.0) (com.blacksquared.changers-sdk) SDK',
            },
            motiontag: {
              last_transmitted: '08.09.20, 11:05',
              last_event: '08.09.20, 11:05',
            },
            pp_accepted: true,
            app_version: '1.0.0',
          },
        }),
      );
    }, console.error);
  };

  // @ts-ignore
  window.registerWidget = function (
    elementName: string,
    scriptUrl: string,
    position: string,
    config?: any
  ) {
    console.log(
      'Registering widget',
      elementName,
      scriptUrl,
      position,
      widgetsLoading,
    );
    setLogs((arr) => [
      ...arr,
      `Registering widget ${elementName}, ${scriptUrl}, ${position}, ${widgetsLoading}`,
    ]);

    if (widgetsLoading.includes(elementName))
      return console.log('Widget already queued for loading once');

    setWidgetsLoading((arr) => [...arr, elementName]);

    const scriptElement: HTMLScriptElement = document.createElement('script');
    scriptElement.src = scriptUrl;
    scriptElement.id = 'widget-' + elementName;

    document.body.appendChild(scriptElement);

    scriptElement.onload = () => {
      console.log(
        'Widget script loaded, creating component..',
        elementName,
        scriptUrl,
        position,
      );
      setLogs((arr) => [
        ...arr,
        `Widget script loaded, creating component ${elementName}, ${scriptUrl}, ${position}`,
      ]);

      const reactComponent = createComponent(
        React,
        elementName,
        customElements.get(elementName) as any,
        {
          onactivate: 'activate',
          onchange: 'change',
        },
      );

      var widgetRef = React.createRef();

      setWidgetRegistry((arr) => [
        ...arr,
        {reactComponent, elementName, scriptUrl, position, widgetRef},
      ]);

      // @ts-ignore
      window.debugWidget = {
        reactComponent,
        elementName,
        scriptUrl,
        position,
        widgetRef,
      };

      if (typeof config !== 'undefined' && config?.additionalElements) {
        let newElements :any[] = [];

        for (let additionalElement of config.additionalElements) {
          console.log('Registering additional element', additionalElement);

          const additionalReactComponent = createComponent(
            React,
            additionalElement,
            customElements.get(additionalElement) as any,
            {
              onactivate: 'activate',
              onchange: 'change',
            },
          );

          var additionalWidgetRef = React.createRef();

          newElements.push({
            reactComponent: additionalReactComponent, 
            elementName: additionalElement, 
            scriptUrl: scriptUrl, 
            position: position, 
            widgetRef: additionalWidgetRef
          });
        }

        console.log("Adding addional elements", newElements);

        setWidgetRegistry((arr) => [...arr,...newElements]);
      }

      console.log('component created, registry updated');
      setLogs((arr) => [...arr, `Component created, registry updated`]);
    };

    scriptElement.onerror = (error) => {
      console.log('Widget script error', error);
      setLogs((arr) => [...arr, `Widget script error ${error}`]);

      setWidgetsLoading((arr) => {
        arr = arr.filter(function (item) {
          return item !== elementName;
        });

        return arr;
      });
    };
  };

  const value = {
    widgetRegistry,
    logs,
  };

  return (
    <OpenWidgetContext.Provider value={value}>
      {props.children}
    </OpenWidgetContext.Provider>
  );
};

export default OpenWidgetProvider;
