import merge from 'lodash/merge';

import base from 'config/organizations/base';
import {getNewsFeed} from 'services/klimataler/news-feed';

import HammCoin from 'components/ClimateWidgets/Co2Token/HammCoin';
import teamGroupBannerImg from 'components/ClimateWidgets/Leaderboard/images/klimataler/group-banner.svg';
import fullTokenImg from "components/ClimateWidgets/Co2Token/HammCoin/images/christmas-coins/full.svg";

const config = {
  name: 'klimataler',
  theme: {
    fonts: {},
    colors: {},
    components: {
      nav: {
        routes: ['impact', 'offers', 'teams', 'news', 'more'],
      },
      leaderboard: {
        groupBanner: teamGroupBannerImg,
      },
    },
  },
  features: {
    hideLotsTab: true,
    hideAppClose: true,
    hasCompetition: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hasUserAccount: true,
    hasCo2Compensation: true,
    hasCustomActivities: true,
    hasBadges: true,
    hasNewFAQ: true,
    hasCo2Emissions: true,
    hasMetering: true,
    hasNotifications: true,
    hasWidgetCustomization: true,
    hasWallets: true,
    hasNewQRScanner: true,
    hasVehicleSettings: true,
    hasClimateTreasure: true,
    hasUserRanking: true,
    hasUserRankingMobility: true,
    hideUserRankingParticipants: true,
    hideUserRankingInCities: true,
    hasSocioDemoData: true,
    hasCitiesLeaderboard: true,
    hasGNUTaler: true,
    stage: {
      hideLotsTab: false,
      hasBambooCoin: false,
    },
  },
  translations: {
    competition: {
      title: 'sdk.web.teams.cities.leaderboard.title',
      subtitle: 'sdk.web.teams.cities.leaderboard.subtitle',
    },
  },
  services: {
    getNewsFeed: getNewsFeed,
  },
  applangaTag: '5f4ae66d91adce6141e1fe42',
  fullTokenImg,
  FullTokenComponent: HammCoin,
};

export default merge({}, base, config);
