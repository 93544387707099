import React, {useContext, useState} from 'react';

import routes from 'config/routes';
import {SettingsContext} from 'contexts/SettingsContext';
import useNavigation from 'hooks/app/use-navigation';
import {useTranslations} from 'hooks/use-translations';

import InfoPage from 'components/InfoPage/InfoPage';
import {ReactComponent as FilterIcon} from 'icons/filter.svg';

const Map = React.lazy(() => import('pages/ClimateTreasure/Map'));

const ClimateTreasure = () => {
  const {organizationConfig} = useContext(SettingsContext);
  const {handleGoBack} = useNavigation({
    replaceLocation:
      routes.DASHBOARD.CONTRIBUTIONS.WIDGETS_SEARCH.CLIMATE_TREASURE,
  });
  const {translate} = useTranslations();
  const [showFilters, setShowFilters] = useState(false);

  const title = translate(
    organizationConfig.translations.climateTreasure?.title ||
      'sdk.web.treasure.map.title',
  );

  return (
    <InfoPage
      title={title}
      noPadding
      actionButton={{
        Icon: FilterIcon,
        onClick: () => setShowFilters(true),
      }}
      onClose={handleGoBack}>
      <Map
        showFilters={showFilters}
        onFiltersOpen={() => setShowFilters(true)}
        onFiltersClose={() => setShowFilters(false)}
      />
    </InfoPage>
  );
};

export default ClimateTreasure;
